// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import { css } from "glamor";

import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components

import Form from 'antd/lib/form'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/form/style/css'

import Input from 'antd/lib/input'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/input/style/css'

import Button from 'antd/lib/button'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/button/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import {
  hasErrors,
  validateEmail,
  validateName,
  validateComment,
  validatemobileNumber,
} from '../methods/formHelpers'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React;
const FormItem = Form.Item
const { TextArea } = Input

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** AdmissionIntentForm */
class AdmissionIntentForm extends React.Component {
  /** standard constructor. */
  constructor(props) {
    super(props)

    this.state = {
      loader: null,
      formSent: false,
    }

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  /** handleSubmit - Post to google spreadsheet. */
  componentDidMount() {
    this.props.form.validateFields()
  }

  /** handleSubmit - Post to google spreadsheet. */
  handleSubmit(e) {
    e.preventDefault()
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // console.log('Received values of form: ', values);
        this.setState({
          // Show loader and reset errors if any.
          loader: true,
        })

        let {
          name,
          email,
          comment,
          mobileNumber,
          candidateName,
          admissionsSoughtFor,
        } = values

        name = isUndefined(name) ? ' ' : name
        email = isUndefined(email) ? ' ' : email
        comment = isUndefined(comment) ? ' ' : comment
        mobileNumber = isUndefined(mobileNumber) ? ' ' : mobileNumber
        candidateName = isUndefined(candidateName) ? ' ' : candidateName
        admissionsSoughtFor = isUndefined(admissionsSoughtFor)
          ? ' '
          : admissionsSoughtFor

        // Mock some delay
        setTimeout(() => {
          fetch(
            `https://script.google.com/macros/s/AKfycbzLZZLts-tSMdqKWOhsmo6-1XKmGtIVV6QZoaX-hUAp1z6aFRY/exec?email=${email}&name=${name}&comment=${comment}&candidateName=${candidateName}&admissionsSoughtFor=${admissionsSoughtFor}&mobileNumber=${mobileNumber}&callback=?`,
            {
              method: 'GET',
              mode: 'no-cors',
            }
          )
            .then(response => {
              this.setState({
                loader: false,
                formSent: true,
              })
            })
            .catch(error => {
              this.setState({
                loader: false,
              })
            })
        }, 1500)
      }
    })
  }

  /** standard renderer */
  render() {
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form
    // Only show error after a field is touched.
    const nameError = isFieldTouched('name') && getFieldError('name')
    const mobileNumberError =
      isFieldTouched('mobileNumber') && getFieldError('mobileNumber')
    const emailError = isFieldTouched('email') && getFieldError('email')
    const commentError = isFieldTouched('comment') && getFieldError('comment')
    const candidateNameError =
      isFieldTouched('candidateName') && getFieldError('candidateName')
    const admissionsSoughtForError =
      isFieldTouched('admissionsSoughtFor') &&
      getFieldError('admissionsSoughtFor')

    return (
      <div>
        {this.state.formSent === false && (
          <Form onSubmit={this.handleSubmit}>
            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Name */}
            <FormItem
              validateStatus={nameError ? 'error' : ''}
              help={nameError || ''}
            >
              {getFieldDecorator('name', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateComment }],
              })(<Input placeholder="Parent or Guardian's name" />)}
            </FormItem>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Email */}
            <FormItem
              validateStatus={emailError ? 'error' : ''}
              help={emailError || ''}
            >
              {getFieldDecorator('email', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateEmail }],
              })(<Input placeholder="Parent or Guardian's email" />)}
            </FormItem>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Mobile Number */}
            <FormItem
              validateStatus={mobileNumberError ? 'error' : ''}
              help={mobileNumberError || ''}
            >
              {getFieldDecorator('mobileNumber', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateComment }],
              })(<Input placeholder="Parent or Guardian's mobile number" />)}
            </FormItem>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Candidate Name */}
            <FormItem
              validateStatus={candidateNameError ? 'error' : ''}
              help={candidateNameError || ''}
            >
              {getFieldDecorator('candidateName', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateComment }],
              })(<Input placeholder="Candidate's Name" />)}
            </FormItem>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Admission Sought For */}
            <FormItem
              validateStatus={admissionsSoughtForError ? 'error' : ''}
              help={admissionsSoughtForError || ''}
            >
              {getFieldDecorator('admissionsSoughtFor', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateComment }],
              })(
                <Input placeholder="Class to which admission is sought for" />
              )}
            </FormItem>

            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Comment */}
            <FormItem
              validateStatus={commentError ? 'error' : ''}
              help={commentError || ''}
            >
              {getFieldDecorator('comment', {
                validateTrigger: ['onChange', 'onBlur'],
                rules: [{ validator: validateComment }],
              })(
                <TextArea
                  placeholder="When would you like to meet us? Do you have any comments or questions for us? Do you have any specific requests?"
                  autosize={{ minRows: 3, maxRows: 6 }}
                />
              )}
            </FormItem>
            {/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Submit */}
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
                loading={this.state.loader}
              >
                Submit
              </Button>
            </FormItem>
          </Form>
        )}
        {/* On-sent message */}
        {this.state.formSent === true && (
          <p className="home" style={{ textIndent: 0 }}>
            We recieved your request. We'll get back to you shortly.
          </p>
        )}
      </div>
    )
  }
}

const WrappedForm = Form.create()(AdmissionIntentForm)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default WrappedForm
