// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import OutLink from '@bodhi-project/components/lib/OutLink'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

import AdmissionIntentForm from '../components/AdmissionIntentForm'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Apply Online',
  nakedPageSlug: 'apply-online',
  pageAbstract:
    'From 2018 our admission process is digitsed for your convenience. We follow the regular process every year. The candidate must be registered prior to the closing admission date. The last date will be announced by the school. But usually this is in March.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h2': {
    borderTop: '2.6px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }}>
      <Col sm={24} md={16}>
        <h1 className="mask-h3">Admission Process</h1>
        <p>
          From 2018 our admission process is digitised for your convenience. We
          follow the regular process every year. The candidate must be
          registered prior to the closing admission date. The last date will be
          announced by the school. But usually this is in March. The process is:
        </p>
        <ol className="mask-p">
          <li>
            <strong>Fill out the intent form</strong>: Please fill out the
            intent form below and let us know your interest in admissions to our
            school. We will get back to you within 2 weeks with an appointment
            date if there are seats available.
          </li>
          <li>
            <strong>Meet us in-person</strong>: We would like to meet the
            candidate and you, the parents or guardians. You can collect the
            prospectus, a detailed registeration form and more documents from
            the school on the same day. There is a small fee for the prospectus.
          </li>
          <li>
            <strong>We will send you a response in 2 weeks</strong>: After our
            appointment you will get a response from us within 2 weeks.
          </li>
          <li>
            <strong>
              Pay fees and submit all documents to accept the offer
            </strong>
            : If the candidate is accepted by us you will have 2 weeks to settle
            the first fee installment.
          </li>
        </ol>
        <h2 className="mask-h4">Intent Form</h2>
        <AdmissionIntentForm />
        <h2 className="mask-h4">Documents Required</h2>
        <p>
          Please note that we do not consider an application complete until all
          these documents have been submitted.
        </p>
        <ul className="mask-p">
          <li>
            Three Photographs of the student (one attached to application form)
          </li>
          <li>Self attested copy of Aadhar card of the student</li>
          <li>One photograph each of Mother and Father</li>
          <li>One photograph of Guardian if parents are living abroad</li>
          <li>
            Attested copy of birth certificate of the student clearly specifying
            date of birth (issued by Municipal Corporation or any other
            Competent Authority)
          </li>
          <li>Transfer Certificate (if applicable) from the previous school</li>
          <li>Report/ Mark sheet needed from the previous school</li>
        </ul>
      </Col>
      <Col sm={24} md={8}>
        <h2 className="mask-h4">Admissions Enquiry</h2>
        <p>
          For assistance and help or to see our campus, please visit us at:
          <br />
          <i>Urban Estate, Phase II</i>
          <br />
          <i>Jalandhar, 144002</i>
          <br />
          <i>Punjab</i>
          <br />
          <i>India</i>
        </p>
        <p>
          You can also write an email to us at:
          <br />
          <OutLink to="mailto:admissions@cambridgeinnovativejal.school">
            <i>admissions@cambridgeinnovativejal.school</i>
          </OutLink>
        </p>
        <p>
          Or, you can call us at these numbers between 9AM and 2PM Monday to
          Friday:
          <br />
          <OutLink to="tel:0181-4623951">
            <i>0181-4623951</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623952</i>
          </OutLink>
          <br />
          <OutLink to="tel:0181-4623952">
            <i>0181-4623953</i>
          </OutLink>
        </p>
        <p>
          Do you wish to meet us and have a look around? You can request a
          guided tour by contacting us. Please note that the appointment is
          subject to availability.
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
